export class Randomizer {
  options = {
    length: 16,
    loc: true,
    upc: true,
    num: true,
    sym: true
  }

  constructor (options) {
    this.options = {
      ...this.options,
      ...options
    }
  }

  generate () {
    var length = parseInt(this.options.length)
    if (length <= 0) return ''
    var charset = ''
    if (this.options.loc) {
      charset += 'abcdefghijklmnopqrstuvwxyz'
    }
    if (this.options.upc) {
      charset += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    }
    if (this.options.num) {
      charset += '0123456789'
    }
    if (this.options.sym) {
      charset += '!@#$%^&*()_+{}|:<>?-=[];~'
    }
    if (charset === '') return ''
    return this.random(charset, length)
  }

  random (charset, length) {
    var l = charset.length
    var r = ''
    for (var i = 0; i < length; i++) {
      r += charset[Math.floor(Math.random() * (l - 1))]
    }
    return r
  }
}
