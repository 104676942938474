<template>
  <div class="page page-tools-random">
    <div class="container heading">
      <div class="row">
        <div class="col">
          <h1>Randomizer</h1>
          <hr />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="form-group row">
        <label for="input-length" class="col-sm-2 col-form-label">Length</label>
        <div class="col-sm-10">
          <input
            type="number"
            class="form-control"
            id="input-length"
            placeholder="Length"
            name="length"
            value="16"
            ref="inLength"
          />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Options</label>
        <div class="col-sm-10 col-form-label">
          <div class="container">
            <div class="row">
              <div class="form-check col">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="loc"
                  id="input-option-loc"
                  ref="inLoc"
                  checked
                />
                <label class="form-check-label" for="input-option-loc"
                  >a-z</label
                >
              </div>

              <div class="form-check col">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="upc"
                  id="input-option-upc"
                  ref="inUpc"
                  checked
                />
                <label class="form-check-label" for="input-option-upc"
                  >A-Z</label
                >
              </div>

              <div class="form-check col">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="num"
                  id="input-option-num"
                  ref="inNum"
                  checked
                />
                <label class="form-check-label" for="input-option-num"
                  >0-9</label
                >
              </div>

              <div class="form-check col">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="sym"
                  id="input-option-sym"
                  ref="inSym"
                  checked
                />
                <label class="form-check-label" for="input-option-sym"
                  >!@#</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label"></label>
        <div class="col-sm-10 col-form-label">
          <button type="button" class="btn btn-primary" @click="randomize">
            <i class="fa-solid fa-shuffle"></i> Generate
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-success"
            ref="btnCopy"
            @click="copy"
            v-if="result"
            v-html="copyBtnText"
          ></button>
        </div>
      </div>
      <div class="form-group row" v-if="result">
        <label class="col-sm-2 col-form-label"></label>
        <div class="col-sm-10 col-form-label">
          <input
            id="random-result"
            type="text"
            class="form-control"
            :value="result"
            placeholder="Result"
            readonly
            ref="outResult"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Randomizer } from '@/module/Randomizer.js'

export default {
  name: 'Randomizer',
  data: function () {
    return {
      result: '',
      copyBtnText: '<i class="fa-regular fa-copy"></i> Copy'
    }
  },
  methods: {
    randomize () {
      var randomizer = new Randomizer({
        length: parseInt(this.$refs.inLength.value),
        loc: this.$refs.inLoc.checked,
        upc: this.$refs.inUpc.checked,
        num: this.$refs.inNum.checked,
        sym: this.$refs.inSym.checked
      })
      this.result = randomizer.generate()
    },
    copy () {
      var self = this
      var cb = self.$refs.outResult
      cb.select()
      cb.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(cb.value)
      self.copyBtnText = '<i class="fa-solid fa-check"></i> Copied'
      setTimeout(function () {
        self.copyBtnText = '<i class="fa-regular fa-copy"></i> Copy'
      }, 1000)
    }
  },
  mounted () {}
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/toolshead.scss';

h1 {
  margin-bottom: 10px;
  text-align: left;
  color: $key-yellow;
  font-size: 2.8em;
  font-weight: 700;
}
</style>
